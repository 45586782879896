import { css, styled } from "goober";
import { desktop, mobile } from "clutch/src/Style/style.mjs";

export const OverlayBackdrop = styled("div")<{ $src: string }>`
  width: 100%;
  height: 100%;
  padding: var(--sp-6);

  background-image: url(${({ $src }) => $src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SelectionContentWrapper = styled("div")`
  transition: var(--transition);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--shade6);
  padding: var(--sp-0_5);

  .inner-wrap {
    padding: var(--sp-2) var(--sp-4);
    gap: var(--sp-4);
    border-radius: var(--sp-1);
  }
`;

export const SelectionWrapper = styled("div")`
  /* subtle but this is what I see in the designs */
  padding-top: var(--sp-1);
  color: var(--shade1);

  transition: var(--transition);
  transition-property: color, border-color, background-color;

  &.disabled {
    color: var(--shade2);
    opacity: 0.5;
    pointer-events: none;
  }

  &.selected:not(.disabled) {
    color: var(--turq);

    .wrap {
      border-color: var(--turq);
    }

    .wrap {
      img,
      svg,
      .inner-wrap {
        background-color: hsla(var(--turq-hsl) / 0.15);
      }
    }
  }

  &:hover:not(.selected) :is(.br, .br-lg) {
    border-color: var(--shade4);
  }

  &:hover:not(.selected) {
    color: var(--shade1);
  }
`;

export const SelectionTextWrapper = styled("div")`
  transition: var(--transition);
  color: inherit;
  margin: var(--sp-2) var(--sp-1);
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;

  .icon {
    width: var(--sp-4);
    height: var(--sp-4);
  }
  .lock {
  }
  .check {
    padding: var(--sp-0_5);
  }
`;

export const cssHideOnMobile = () => css`
  ${desktop} {
    width: 0;
    height: 0;
  }
`;

export const SelectionImageWrapper = styled("div")`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2.5px solid var(--shade6);
  padding: var(--sp-0_5);
`;

export const SelectionRadioWrapper = styled("div")`
  padding: var(--sp-2) var(--sp-4);
  border: 2.5px solid var(--shade6);

  transform: scale(1);

  &:hover:not(.disabled) {
    transform: scale(1.02);
  }

  &:active:not(.disabled) {
    transform: scale(0.98);
  }
`;

export const OverlayConfigTitle = styled("div")`
  padding: var(--sp-6);
  border-bottom: 1px solid var(--shade6);

  .title-toggle {
    display: flex;
    justify-content: space-between;
  }

  .notification {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
    padding: var(--sp-2) var(--sp-3);
    background: color-mix(in hsl, var(--red), transparent 85%);
    color: var(--red);
    border-radius: var(--br);
  }
`;

export const OverlayPageContainer = styled("div")`
  display: flex;
  > * {
    flex: 1;
  }
  align-items: stretch;

  .overlay-preview {
    ${mobile} {
      max-height: 35vh;

      overflow: hidden;
    }
  }

  .overlay-preview > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: var(--shade9);
  }

  .overlay-preview > div > div {
    width: auto;
  }

  .content-column {
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  min-height: calc(var(--sp-container) * 0.62);
  max-height: calc(100vh - 2 * var(--sp-12));
  max-width: calc(var(--sp-container) + var(--sp-12) * 2);
  width: calc(100vw - 2 * var(--sp-12));

  .hint svg {
    color: var(--shade1);
    width: var(--sp-5);
  }

  ${mobile} {
    flex-direction: column-reverse;

    width: 100vw;
    min-height: 100vh;
    max-height: unset;

    border-radius: 0;
  }
`;

export const OverlayWrap = styled("div")`
  display: flex;
`;
