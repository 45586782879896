import React, { forwardRef, Suspense, useEffect, useState } from "react";
import { styled } from "goober";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";
import type { TFunction } from "i18next";

import { readPath } from "@/__main__/get-data.mjs";
import { GAME_BOX_ICONS } from "@/app/constants.mjs";
import type {
  GameOverlay,
  GameOverlayFeature,
  OverlayFeatureSelect,
  OverlayFeatureToggle,
} from "@/hub-overlays/constants.mjs";
import {
  GAME_OVERLAY_BACKGROUNDS,
  GAME_OVERLAY_BACKGROUNDS_OFFSET,
} from "@/hub-overlays/constants.mjs";
import { OverlayTag } from "@/hub-overlays/Overlays.style.jsx";
import InfoIcon from "@/inline-assets/blitz-info-border.svg";
import LockIcon from "@/inline-assets/blitz-lock-2.svg";
import CheckIcon from "@/inline-assets/check-small.svg";
import {
  isOverlayEnabled,
  setOverlayEnabled,
  useOverlaySettings,
  writeOverlaySetting,
} from "@/library/actions.mjs";
import FreeTrialButton from "@/library/FreeTrialButton.jsx";
import KeybindView from "@/library/KeybindView.jsx";
import {
  cssHideOnMobile,
  OverlayBackdrop,
  OverlayConfigTitle,
  OverlayPageContainer,
  OverlayWrap,
  SelectionContentWrapper,
  SelectionTextWrapper,
  SelectionWrapper,
} from "@/library/OverlayConfigureModal.style.jsx";
import { readSelectSetting, readToggleSetting } from "@/library/utils.mjs";
import FullModal from "@/shared/FullModal.jsx";
import Tilting from "@/shared/Tilting.jsx";
import BlitzPortal from "@/util/BlitzPortal.jsx";
import { classNames } from "@/util/class-names.mjs";
import { devError } from "@/util/dev.mjs";
import isEmpty from "@/util/is-empty.mjs";
import useTFunction from "@/util/use-t-function.mjs";

type OverlayConfigureModalProps = {
  overlay?: GameOverlay;
  handleClose: () => void;
};

function findFallback(key, optionValue) {
  // LoL team has been periodically REMOVING/changing/override user values,
  // this is a means to gracfully map those old value to their new ones
  // (without having to forcfully re-write the settings value itself)

  const fallbackMap = {
    "benchmarking:csPerMinute:basic": "legacy",
    "junglePathingBenchmarks:benchmark:division": "simple",
  };

  return fallbackMap[`${key.join(":")}:${optionValue}`];
}

const noFeatures = [
  {
    text: ["overlays:noOptions.title", "Options"],
    items: [
      {
        text: [
          "overlays:noOptions.description",
          "Nothing to configure, this one is plug and play!",
        ],
      },
    ],
  },
];

const OverlayConfigureModal = (
  {
    overlay,
    handleClose,
  }: OverlayConfigureModalProps & React.ComponentPropsWithoutRef<"div">,
  ref: React.RefObject<HTMLDialogElement>,
) => {
  const game = overlay.game;
  const settingsState = useOverlaySettings(game);
  const t = useTFunction();
  const Icon = GAME_BOX_ICONS[game];

  const features = isEmpty(overlay.features) ? noFeatures : overlay.features;

  const [actualFeatures, setActualFeatures] = useState(
    Array.isArray(features) ? features : [],
  );

  useEffect(() => {
    if (typeof features !== "function") return;
    features().then(setActualFeatures, devError);
  }, [features]);

  return (
    <BlitzPortal>
      <FullModal onModalClose={handleClose} ref={ref}>
        <OverlayWrap>
          <OverlayPageContainer className="overflow-hidden card-container transition">
            <div className="content-column">
              <OverlayConfigTitle className="flex column gap-sp-3">
                <div className="title-toggle">
                  <h2 className="type-article-headline">{t(overlay.title)}</h2>
                  {!overlay.noToggle ? (
                    <OverlayEnableToggle overlay={overlay} />
                  ) : null}
                </div>
                <ul className="flex gap-sp-1">
                  {Icon && <Icon className="icon-color" />}
                  {overlay.tags?.map((tag) => (
                    <OverlayTag key={tag} tag={tag} />
                  ))}
                </ul>
                <p className="type-body2 shade1">{t(overlay.description)}</p>
                {overlay.notification ? (
                  <div className="notification">
                    <InfoIcon />
                    <p className="type-caption--bold">
                      {t(overlay.notification)}
                    </p>
                  </div>
                ) : null}
              </OverlayConfigTitle>
              <FeaturesList className="scroll-y flex-grow flex column">
                {actualFeatures.map((feature, i) => {
                  const isFn = typeof feature === "function";
                  const FeatureComponent = isFn ? feature : null;

                  return FeatureComponent ? (
                    <li className="flex column gap-sp-3" key={i}>
                      <FeatureComponent />
                    </li>
                  ) : (
                    <RenderFeature
                      as="li"
                      key={i}
                      game={game}
                      {...{ settingsState, feature }}
                    />
                  );
                })}
              </FeaturesList>
            </div>
            <div className="overlay-preview relative">
              {overlay.Preview ? (
                <OverlayBackdrop
                  className="flex justify-center align-center"
                  $src={GAME_OVERLAY_BACKGROUNDS[game]}
                  style={{
                    backgroundPosition: GAME_OVERLAY_BACKGROUNDS_OFFSET[game],
                  }}
                >
                  <Suspense>
                    <overlay.Preview />
                  </Suspense>
                </OverlayBackdrop>
              ) : overlay.images?.large ? (
                <img src={overlay.images.large} />
              ) : null}
            </div>
          </OverlayPageContainer>
        </OverlayWrap>
      </FullModal>
    </BlitzPortal>
  );
};

const RenderFeature = ({
  as: FeatureWrapper = "div",
  feature: {
    text,
    description,
    toggle,
    disabled,
    freeTrialDate,
    items,
    renderProButton,
    hint,
    show,
  },
  game,
  settingsState,
}: {
  as?: "div" | "li";
  feature: GameOverlayFeature;
  game: symbol;
  settingsState;
}) => {
  const t = useTFunction();

  if (show && !show(settingsState)) return null;

  const freeTrialExpired = freeTrialDate && new Date() > freeTrialDate;

  const proButton = renderProButton?.(t as TFunction);

  return (
    <FeatureWrapper>
      <div className="flex between align-center">
        <div>
          <h3 className="flex align-center gap-sp-2 type-article-headline">
            {t(text)}
            {hint && <RenderHint t={t} hint={hint} />}
          </h3>
          {description && (
            <p className="type-caption--semi shade1">{t(description)}</p>
          )}
        </div>
        {toggle && (
          <RenderToggle
            disabled={disabled?.(settingsState)}
            {...{
              game,
              toggle,
              settingsState,
              freeTrialDate,
            }}
          />
        )}
      </div>
      {items?.map((item, i) => (
        <RenderItem key={i} {...{ game, item, settingsState }} />
      ))}
      {proButton && (!freeTrialDate || freeTrialExpired) ? (
        <div>{proButton}</div>
      ) : proButton && freeTrialDate && !freeTrialExpired ? (
        <FreeTrialButton freeTrialDate={freeTrialDate} />
      ) : null}
    </FeatureWrapper>
  );
};

const RenderItem = ({
  item: { text, toggle, select, disabled, hint, title, hotkey, show },
  game,
  settingsState,
}: {
  item: GameOverlayFeature;
  game: symbol;
  settingsState;
}) => {
  const t = useTFunction();
  if (show && !show(settingsState)) return null;

  return (
    <>
      <div
        {...classNames(
          "flex between align-center gap-sp-4",
          title && "feature-has-title",
        )}
      >
        <div style={{ flex: 1 }}>
          <div {...classNames("flex align-center gap-2")}>
            {title && <h3 className="type-body2-form--active">{t(title)}</h3>}
            {hint && <RenderHint t={t} hint={hint} />}
          </div>
          {text && <p className="type-caption--semi shade1">{t(text)}</p>}
        </div>
        {toggle && (
          <RenderToggle
            disabled={
              disabled?.(settingsState) || toggle.disabled?.(settingsState)
            }
            {...{ game, toggle, settingsState }}
          />
        )}
        {hotkey && <RenderHotkey {...{ hotkey, settingsState, game }} />}
      </div>
      {select && (
        <RenderSelection
          disabled={
            disabled?.(settingsState) || select.disabled?.(settingsState)
          } // TEMP: never lock
          value={
            select.key ? readSelectSetting(settingsState, select) : select.value
          }
          {...{ game, select, settingsState }}
        />
      )}
    </>
  );
};

const RenderHotkey = ({ hotkey: { key }, settingsState, game }) => {
  const value = readPath(key, settingsState).value || "?";
  return (
    <KeybindView
      keybind={value}
      editable
      onChange={(e) => {
        writeOverlaySetting(game, key, e);
      }}
    />
  );
};

const RenderHint = ({ hint, t }: { hint: GameOverlayFeature["hint"]; t }) => (
  <div className="hint" data-tooltip={t(hint)} data-place="right">
    {React.createElement(hint.icon || InfoIcon)}
  </div>
);

const FeaturesList = styled("ul")`
  & > li {
    display: grid;
    gap: var(--sp-2);
    padding-block-start: var(--sp-6);
    padding-inline: var(--sp-6) var(--sp-3);
    padding-block-end: var(--sp-6);

    &:empty {
      padding: 0;
      padding: 0;
      border-bottom: none;
    }

    &:has(> :nth-child(2)) > :first-child {
      border-block-end: 1px dotted var(--shade6-75);
      padding-block-end: var(--sp-2);
    }
  }
  > :not(:last-child) {
    border-bottom: 1px solid var(--shade6);
  }
`;

const OverlayEnableToggle = ({ overlay }) => (
  <ToggleSwitch
    color="var(--turq)"
    offColor="var(--shade2)"
    onChange={(v) => setOverlayEnabled(overlay, v)}
    value={isOverlayEnabled(overlay)}
  />
);

const RenderToggle = ({
  toggle,
  settingsState,
  game,
  disabled = false,
  freeTrialDate = null,
}: {
  toggle: OverlayFeatureToggle;
  game: symbol;
  settingsState;
  disabled?: boolean;
  freeTrialDate?: Date;
}) => {
  let disabledVal = false;

  const freeTrialExpired = freeTrialDate && new Date() > freeTrialDate;

  if (disabled) {
    disabledVal = true;
  } else if (freeTrialDate && freeTrialExpired) {
    disabledVal = toggle.disabled?.(settingsState);
  } else if (!freeTrialDate) {
    disabledVal = toggle.disabled?.(settingsState);
  }

  return (
    <ToggleSwitch
      color="var(--turq)"
      offColor="var(--shade2)"
      onChange={(v) => {
        const newVal = toggle.inverse ? !v : v;
        toggle.onChange?.(newVal, settingsState);
        writeOverlaySetting(game, toggle.key, newVal);
      }}
      value={readToggleSetting(settingsState, toggle, freeTrialDate)}
      {...{
        disabled: disabledVal,
      }}
    />
  );
};

const RenderSelection = ({
  select: { key, options, style, onChange },
  value,
  game,
  settingsState,
  disabled: settingDisabledVal,
}: {
  select: OverlayFeatureSelect;
  value?: string | boolean | number | AnyFunction;
  game: symbol;
  settingsState;
  disabled?: boolean;
}) => {
  const settingsValue =
    typeof value === "function" ? value() : findFallback(key, value) || value;

  return (
    <div
      className={`flex between gap-sp-2 w-full ${
        style === "radio" ? "column" : ""
      }`}
    >
      {options.map((option, i) => {
        const {
          text,
          description,
          value: optionValue,
          Icon,
          image,
          disabled: optionDisabled,
        } = option;
        const isSelected = settingsValue === optionValue;
        const disabled = settingDisabledVal || optionDisabled?.();

        if (style === "radio")
          return (
            <RenderSelectionRadio
              key={i}
              onClick={() => {
                if (disabled) return;
                onChange?.(optionValue, settingsState);
                if (key) writeOverlaySetting(game, key, optionValue);
              }}
              isSelected={isSelected}
              disabled={disabled}
              {...{
                text,
                description,
                settingsValue,
              }}
            />
          );

        return (
          <RenderSelectionImage
            key={i}
            isSelected={isSelected}
            disabled={disabled}
            totalOptionsCount={options.length}
            onClick={() => {
              if (disabled) return;
              onChange?.(optionValue, settingsState);
              if (key) writeOverlaySetting(game, key, optionValue);
            }}
            {...{
              settingsValue,
              image,
              Icon,
              text,
            }}
          />
        );
      })}
    </div>
  );
};

const RenderSelectionImage = ({
  Icon,
  image,
  text,
  isSelected,
  disabled = false,
  onClick,
  settingsValue,
  totalOptionsCount,
}) => {
  const t = useTFunction();
  const StyledIcon = Icon
    ? styled(Icon)`
        padding: var(--sp-1);
        width: 100%;
        ${totalOptionsCount > 4 ? "height: 100%;" : ""};
      `
    : null;

  return (
    <SelectionWrapper
      onClick={onClick}
      data-value={settingsValue}
      {...classNames(
        "min-w-0",
        "w-full",
        disabled && "disabled",
        isSelected && "selected",
      )}
    >
      <Tilting tiltEnable={!disabled}>
        <SelectionContentWrapper
          {...classNames("wrap", Icon ? "br" : "br-lg", disabled && "disabled")}
        >
          {StyledIcon && <StyledIcon />}
          {image && <img className="br w-full" src={image} />}
        </SelectionContentWrapper>
      </Tilting>
      {text && (
        <SelectionTextWrapper
          {...classNames(
            "flex align-center gap-sp-2",
            (isSelected || disabled) && "has-icon",
          )}
        >
          {isSelected ? (
            <CheckIcon className={`icon check ${cssHideOnMobile()}`} />
          ) : disabled ? (
            <LockIcon className={`icon lock ${cssHideOnMobile()}`} />
          ) : null}
          <span className="type-form--button">{t(text)}</span>
        </SelectionTextWrapper>
      )}
    </SelectionWrapper>
  );
};

const RenderSelectionRadio = ({
  text,
  isSelected,
  disabled,
  onClick,
  description,
  settingsValue,
}) => {
  const t = useTFunction();
  return (
    <SelectionWrapper
      onClick={onClick}
      data-value={settingsValue}
      {...classNames(
        "min-w-0",
        disabled && "disabled",
        isSelected && "selected",
      )}
    >
      <SelectionContentWrapper
        {...classNames(
          "br-lg",
          disabled && "disabled",
          "flex",
          "align-center",
          "space-between",
          "gap-sp-4",
          "wrap",
        )}
      >
        <div className="inner-wrap align-center flex flex-grow">
          <RadioOuterCircle className={isSelected && "selected"}>
            <RadioInnerCircle className={isSelected && "selected"} />
          </RadioOuterCircle>
          <div>
            <div className="type-subtitle2 shade0">{t(text)}</div>
            {description && (
              <div className="type-body2 shade1">{t(description)}</div>
            )}
          </div>
        </div>
      </SelectionContentWrapper>
    </SelectionWrapper>
  );
};

const RadioOuterCircle = styled("div")`
  width: var(--sp-4);
  height: var(--sp-4);

  display: inline-block;
  border-radius: 50%;
  border: var(--sp-0_5) solid var(--shade3);

  &.selected {
    border: var(--sp-0_5) solid var(--turq);
  }
`;

const RadioInnerCircle = styled("div")`
  width: var(--sp-2);
  height: var(--sp-2);

  margin: var(--sp-0_5);
  border-radius: 50%;
  &.selected {
    background-color: var(--turq);
  }
`;

export default forwardRef(OverlayConfigureModal);
